import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { toFixed1IfDecimal } from '../../../../../../va-corejs-v3/utils'
import { bestWorstLevels as getBestWorstlevels } from '../../../services/comparisonBoard'
import nodeDefinitionTypeMap from '../../../../../components/scoring_tree/helper/nodeDefinitionTypeMap'
import * as renaultProjectModeActionCreators from '../../../state/actions'
import {
  selectComparisonBoardOption,
  selectComparisonBoardProducts,
  selectComparisonBoardProductTemplate,
  selectIsExpandedComparisonBoardRow,
} from '../../../state/comparisonBoard/selectors'
import HeaderCell from './HeaderCell'
import ScoreCell from './ScoreCell'
import {
  RPM_COMPARISON_BOARD_OPTION_BEST_WORST,
  RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE,
} from '../../../consts/comparisonBoard'

const classNames = require('classnames')
const uuidv4 = require('uuid/v4')

function BaseBodyRow({ nodeDefinition, template, productsData, expanded, bestWorstOption, filteredIds }) {
  const [childrenNodeDefinitions, setChildrenNodeDefinitions] = useState([])
  const { type, bonus_demerit: bonusDemerit } = nodeDefinition

  useEffect(() => {
    if (childrenNodeDefinitions.length === 0) {
      const childrenIds = nodeDefinition.children_ids
      const children = _.filter(template.node_definitions, item => childrenIds.includes(item.id))

      setChildrenNodeDefinitions(_.values(children))
    }
  }, [template])

  useEffect(
    () => () => {
      // Collapse the row when it is unmounted
      // collapseRow(nodeDefinition.id)
    },
    []
  )

  const bestWorstLevels = useMemo(() => {
    if (!bestWorstOption || type !== nodeDefinitionTypeMap.item) {
      return null
    }

    return getBestWorstlevels(nodeDefinition.id, productsData)
  }, [nodeDefinition, productsData, bestWorstOption])

  const memoizedScoreCells = useMemo(() => {
    return _.map(productsData, (product, index) => {
      const node = _.find(product.nodes, _n => _n.node_definition_id === nodeDefinition.id)

      // For bonus demerit score I have to use a random key since there isn't any
      // valid property that changes after a score update in upper level nodes.
      // This is not a best practice, this is a workaround
      const loopKey = bonusDemerit
        ? uuidv4()
        : `cell-${product.product_id}-${nodeDefinition.id}-${toFixed1IfDecimal(node?.score)}`

      const hasBestWorstClass = bestWorstLevels !== null && bestWorstLevels[product.product_id] !== undefined
      const bestWorstClass = hasBestWorstClass ? bestWorstLevels[product.product_id] : null

      return (
        <td
          key={loopKey}
          className={classNames('comparison-board-table__value-cell', `comparison-board-table__value-cell--${index}`, {
            'comparison-board-table__value-cell--last': productsData.length === index + 1,
            [`comparison-board-table-best-worst--${bestWorstClass}`]: hasBestWorstClass,
          })}
        >
          {type !== nodeDefinitionTypeMap.item && type !== nodeDefinitionTypeMap.criterion && (
            <div className="comparison-board-table__value-cell-shadow" aria-hidden="true" />
          )}
          <ScoreCell product={product} nodeDefinition={nodeDefinition} />
        </td>
      )
    })
  }, [productsData])

  const isFiltered = !filteredIds || Object.keys(filteredIds).length === 0 || filteredIds?.[nodeDefinition.id]

  return (
    <>
      {isFiltered && (
        <>
          <tr
            className={classNames(
              'comparison-board-table__body-row',
              `comparison-board-table__body-row--${type.replace(' ', '-')}`
            )}
          >
            <td className="comparison-board-table__header-cell comparison-board-table__header-cell--node-definition">
              <HeaderCell nodeDefinition={nodeDefinition} />
            </td>
            {memoizedScoreCells}
          </tr>

          {expanded &&
            childrenNodeDefinitions.map(item => (
              <BodyRow nodeDefinition={item} key={`row-${item.id}`} filteredIds={filteredIds} />
            ))}
        </>
      )}
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    texts: state.texts.values,
    productsData: selectComparisonBoardProducts(state),
    template: selectComparisonBoardProductTemplate(state),
    expanded: selectIsExpandedComparisonBoardRow(state, ownProps.nodeDefinition.id),
    bestWorstOption: selectComparisonBoardOption(state, RPM_COMPARISON_BOARD_OPTION_BEST_WORST),
    modelChangeOption: selectComparisonBoardOption(state, RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE),
  }
}

const BodyRow = connect(mapStateToProps, renaultProjectModeActionCreators)(BaseBodyRow)

export default BodyRow
