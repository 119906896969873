import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import ResetIcon from '../../../../../../assets/svg/reset-icon.svg'
import Popover, { POPOVER_POSITION_TOP_CENTER } from '../../Common/Popover'

const classNames = require('classnames')

function ScoreSelector({
  nodeId,
  productId,
  bonusDemerit,
  onClickAway,
  anchorEl,
  environment,
  updateComparisonBoardProductNodeScore,
  resetComparisonBoardProductNodeScore,
}) {
  const minScore = bonusDemerit
    ? parseInt(environment.config.demerit_min_score, 10)
    : parseInt(environment.config.min_score, 10)
  const maxScore = bonusDemerit
    ? parseInt(environment.config.demerit_max_score, 10)
    : parseInt(environment.config.max_score, 10)

  const buttons = useMemo(() => {
    const html = []
    for (let i = minScore; i <= maxScore; i += 1) {
      const classSuffix = i < 0 ? `n${Math.abs(i)}` : i
      html.push(
        <button
          type="button"
          key={`product-score-node-${nodeId}-score-${i}`}
          className={classNames('product-score__stamp', `product-score__stamp--score-${classSuffix}`, {
            'product-score__stamp--bonus-demerit': bonusDemerit,
          })}
          onClick={() => updateComparisonBoardProductNodeScore(productId, nodeId, i)}
        >
          {i}
        </button>
      )
    }

    return html
  }, [])

  return (
    <Popover
      className="product-score-selector"
      onClickAway={onClickAway}
      anchorEl={anchorEl}
      position={POPOVER_POSITION_TOP_CENTER}
      margin={4}
      width={23}
      topLimit={400}
    >
      {buttons}
      <div className="product-score-selector__separator" aria-hidden="true" />
      <button
        type="button"
        className={classNames('product-score__stamp', `product-score__stamp--reset`)}
        title="Reset"
        onClick={() => resetComparisonBoardProductNodeScore(productId, nodeId)}
      >
        <ResetIcon width="11" />
      </button>
    </Popover>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ScoreSelector)
